<template>
  <section id="get-in-touch" class="overflow-hidden">
    <v-row class="secondary white--text" no-gutters justify="center">
      <v-col class="pa-5" cols="12" md="6">
        <base-bubble-1 />

        <base-heading class="mb-5">
          Get In Touch
        </base-heading>
        <v-sheet color="transparent">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="sendEmail()"
          >
            <input type="hidden" name="to_name" :value="my_name" />
            <v-text-field
              color="accent"
              label="Name"
              name="from_name"
              :rules="nameRules"
              solo
              flat
            />

            <v-text-field
              color="accent"
              label="E-Mail"
              name="from_email"
              :rules="emailRules"
              solo
              flat
            />

            <v-text-field
              color="info"
              label="Subject"
              name="subject"
              :rules="subjectRules"
              solo
              flat
            />

            <v-textarea
              color="info"
              label="Message"
              name="message"
              :rules="contentRules"
              solo
              flat
            />

            <base-btn type="submit">Send</base-btn>
          </v-form>
        </v-sheet>
      </v-col>

      <v-col class="hidden-sm-and-down" md="6">
        <v-img :src="require('@/assets/contact.png')" height="100%" />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="sended"
      :timeout="timeout"
      :color="alertType"
      top
      centered
      tile
    >
      {{ info }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="white" text v-bind="attrs" @click="sended = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script>
import emailjs from "@emailjs/browser";
export default {
  name: "GetInTouch",
  data: () => ({
    valid: true,
    loading: false,
    nameRules: [v => !!v || "Name is required"],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid!"
    ],
    subjectRules: [v => !!v || "Subject is required"],
    contentRules: [v => !!v || "Whrite your message here!"],
    my_name: process.env.MY_NAME,
    service_id: process.env.YOUR_SERVICE_ID,
    template_id: process.env.YOUR_TEMPLATE_ID,
    user_id: process.env.YOUR_USER_ID,
    sended: false,
    alertType: "",
    info: "",
    timeout: 3000
  }),
  methods: {
    sendEmail() {
      if (this.$refs.form.validate()) {
        emailjs
          .sendForm(
            this.service_id,
            this.template_id,
            this.$refs.form.$el,
            this.user_id
          )
          .then(
            () => {
              this.$refs.form.reset();
              this.notification("success", "Message sent successfully!");
            },
            error => {
              this.notification("error", error.text);
            }
          );
      } else {
        this.notification(
          "warning",
          "The form is not filled in. Please enter your email address, your name and the reason for the contact."
        );
      }
    },
    notification(val, info) {
      this.sended = true;
      this.alertType = val;
      this.info = info;
    }
  }
};
</script>
